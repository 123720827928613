<!--
 * @Author: your name
 * @Date: 2020-11-24 14:55:24
 * @LastEditTime: 2021-04-06 09:16:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\adminInverter\createInverter.vue
-->
<template>
    <div>
        <el-divider content-position="left"><span style="font-size:18px;font-weight: 700;">{{$t('inverter.info.add')}}</span></el-divider>
        <el-form ref="form" :model="form" label-width="380px" label-position='left'>
            <el-row :gutter="20">
                <el-col :span="20" :offset="1">
                    <el-divider content-position="left">{{$t('project.regular-data')}}</el-divider>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.data.inv-type')" prop="name" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.name" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.work-tem-min')" prop="tempSurroundingFrom" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.tempSurroundingFrom" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.work-tem-max')" prop="tempSurroundingTo" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.tempSurroundingTo" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.protection-class')" prop="protection" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input  v-model="form.protection" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('new.PV.management.width') + '(mm)'" prop="width" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.width" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.height') + '(mm)'" prop="height" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.height" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.depth') + '(mm)'" prop="depth" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.depth" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('new.PV.management.weight') + '(kg)'" prop="weight" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.weight" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.cross-section-max-dc')" prop="crossSectionMaxDC" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.crossSectionMaxDC" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.cross-section-min-ac')" prop="crossSectionMinAC" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.crossSectionMinAC" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.cross-section-max-ac')" prop="crossSectionMaxAC" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.crossSectionMaxAC" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.u-inv-recommended')" prop="uInvRecommended" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.uInvRecommended" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.p-inv-recommended')" prop="pInvRecommended" >
                        <el-input type="number" v-number v-model="form.pInvRecommended" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.p-inv-cons')" prop="pInvCons" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.pInvCons" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('battery.data.modelSeries')" >
                        <el-select v-model="form.modelSeries" filterable clearable>
                            <el-option
                                    v-for="item in modelSeriesList"
                                    :key="item.value"
                                    :label="item.code"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.is-utilizing-reac-power')" prop="isUtilizingReacPower" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-checkbox v-model="form.isUtilizingReacPower" :true-label="1" :false-label="0"></el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.is-tri-phase')" prop="isTriPhase" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-checkbox v-model="form.isTriPhase" :true-label="1" :false-label="0"></el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('battery.data.IsStorage')" prop="isTriPhase" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-checkbox v-model="form.isStorage" :true-label="1" :false-label="0"></el-checkbox>
                    </el-form-item>
                </el-col>
                
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('battery.data.recommend')" prop="isTriPhase" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-checkbox v-model="form.recommend" :true-label="1" :false-label="0"></el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :span="20" :offset="1">
                    <el-divider content-position="left">{{$t('project.inv.conversion-efficiency')}}</el-divider>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.max-efficiency') + '(%)'" prop="etaInvMax" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.etaInvMax" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.european-efficiency') + '(%)'" prop="etaInvEuropean" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.etaInvEuropean" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.eta-inv-nominal') + '(%)'" prop="etaInvNominal" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.etaInvNominal" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.pic')" prop="uploadFile" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <baseFile type="number" v-number v-model='form.uploadFile' @change='returnImgUrl'></baseFile>
                    </el-form-item>
                    <img :src="imgBaseUrl" alt="" width="150px">
                </el-col>
                <el-col :span="20" :offset="1">
                    <el-divider content-position="left">{{$t('project.input-data')}}</el-divider>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('new.PV.management.Max.DC-power') + '(W)'"  prop="pInvDCmax" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.pInvDCmax" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('new.PV.management.Max.DC-voltage') + '(V)'" prop="uInvDCmax" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.uInvDCmax" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.DC-rated-voltage') + '(V)'" prop="uInvDCnom" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.uInvDCnom" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.strings-number')"  prop="stringsNumber" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input-number v-model="form.stringsNumber" controls-position="right" @change="numberChange" :min="1" :max="10"></el-input-number>
                    </el-form-item>
                </el-col> -->
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.MPPT-count')" prop="inputCount" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number controls-position="right" v-model="form.inputCount" :placeholder="$t('common.info.pl-insert-content')" @change="numberChange" :min="1" :max="50"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
                <template v-for='(item,index) in form.iInvDCObject'>
                        <el-row :gutter="20" :key="index+1" style="margin-top:5px;padding-top:5px;">
                            <el-col :span="10" :offset="1" :key="index+1">
                                <el-form-item :label="returnProNumber(index+1,$t('project.inv.strings-parallel-count'))" :prop="'iInvDCObject.'+index+'.stringsParallelCount'" :rules="[{ required: true, message: $t('remind.not-null')}]">
                                    <el-input type="number" v-number v-model="item[`stringsParallelCount`]" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1" :key="(index+1)*10">
                                <el-form-item :label="returnProNumber(index+1 , $t('project.inv.i-inv-dc-max'))" :prop="'iInvDCObject.'+index+'.iInvDCMax'" :rules="[{ required: true, message: $t('remind.not-null')}]">
                                    <el-input type="number" v-number v-model="item[`iInvDCMax`]" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1" :key="(index+1)*100">
                                <el-form-item :label="returnProNumber(index+1 , $t('project.inv.i-inv-sc-max'))" :prop="'iInvDCObject.'+index+'.iInvSCMax'" :rules="[{ required: true, message: $t('remind.not-null')}]">
                                    <el-input type="number" v-number v-model="item[`iInvSCMax`]" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1" :key="(index+1)*1000">
                                <el-form-item :label="returnProNumber(index+1 , $t('project.inv.pwmax') )" :prop="'iInvDCObject.'+index+'.iInvPWMax'" :rules="[{ required: false, message: $t('remind.not-null')}]">
                                    <el-input type="number" v-number v-model="item[`iInvPWMax`]" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                </template>
                <el-row :gutter="20">
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.u-inv-MPP-max')" prop="uInvMPPmax" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.uInvMPPmax" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.u-inv-MPP-min-from')" prop="uInvMPPminTo" :rules="[{ required: true, message: $t('remind.not-null')},{validator:test_uInvMPPmin,trigger:'blur'}]">
                        <el-input type="number" v-number style="width:80%;" v-model="form.uInvMPPminFrom" :placeholder="$t('common.info.min')"></el-input>
                         - 
                        <el-input type="number" v-number style="width:80%;" v-model="form.uInvMPPminTo" :placeholder="$t('common.info.max')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('Overweight_range')" prop="nomPowerTo" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        
                        <el-input type="number" v-number style="width:80%;" v-model="form.nomPowerTo" :placeholder="$t('common.info.max')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.u-inv-PV-xi')" prop="uInvPVmin" :rules="[{ required: true, message: $t('remind.not-null')},{validator:test_uInvPV,trigger:'blur'}]">
                        <el-input type="number" v-number style="width:80%;" v-model="form.uInvPVmin" :placeholder="$t('common.info.min')"></el-input>
                         - 
                        <el-input type="number" v-number style="width:80%;" v-model="form.uInvPVmax" :placeholder="$t('common.info.max')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="20" :offset="1">
                    <el-divider content-position="left">{{$t('project.output-data')}}</el-divider>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.Max.AC-power') + '(W)'" prop="pInvACmax" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.pInvACmax" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.data.nominal-AC-power') + '(W)'" prop="pInvACnom" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.pInvACnom" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('inverter.info.min.power')" prop="powerFactorMin" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input type="number" v-number v-model="form.powerFactorMin" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.inv.u-inv-AC-from')" prop="uInvACFrom" :rules="[{ required: true, message: $t('remind.not-null')},{validator:test_uInvAC,trigger:'blur'}]">
                        <el-input type="number" v-number style="width:80%;" v-model="form.uInvACFrom" :placeholder="$t('common.info.min')"></el-input>
                         - 
                        <el-input type="number" v-number style="width:80%;" v-model="form.uInvACTo" :placeholder="$t('common.info.max')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item :label="$t('project.grid-frequency') + '(Hz)'" prop="gridFrequFrom" :rules="[{ required: true, message: $t('remind.not-null')},{validator:test_gridFrequ,trigger:'blur'}]">
                        <el-input type="number" v-number style="width:80%;" v-model="form.gridFrequFrom" :placeholder="$t('common.info.min')"></el-input>
                         - 
                        <el-input type="number" v-number style="width:80%;" v-model="form.gridFrequTo" :placeholder="$t('common.info.max')" ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="save-main-box">
            <el-button type="primary" @click="save('form')">{{$t('userInfo.alter')}}</el-button>
            <el-button type="primary" @click="()=>{$router.push({path:'/adminInverter'})}">{{$t('uerInfo.back')}}</el-button>
        </div>
    </div>
</template>

<script>
import baseFile from '../../components/common/baseFileInput'
export default {
    components: {
        baseFile
    },
    props: {

    },
    data() {
        this.test_uInvMPPmin = async(rule, value, callback) => {
            if(this.form.uInvMPPminFrom==''||this.form.uInvMPPminTo == ''){
                callback(new Error(this.$t('remind.not-null')));
            }else{
               callback();
            }
        }
        this.test_uInvPV = async(rule, value, callback) => {
            if(this.form.uInvPVmin==''||this.form.uInvPVmax == ''){
                callback(new Error(this.$t('remind.not-null')));
            }else{
               callback();
            }
        }
        this.test_uInvAC = async(rule, value, callback) => {
            if(this.form.uInvACFrom==''||this.form.uInvACTo == ''){
                callback(new Error(this.$t('remind.not-null')));
            }else{
               callback();
            }
        }
        this.test_gridFrequ = async(rule, value, callback) => {
            if(this.form.gridFrequFrom==''||this.form.gridFrequTo == ''){
                callback(new Error(this.$t('remind.not-null')));
            }else{
               callback();
            }
        }
        return {
            form:{
                snCount:'',
                name:'',
                tempSurroundingFrom:'',
                tempSurroundingTo:'',
                protection:'',
                width:'',
                height:'',
                depth:'',
                weight:'',
                crossSectionMaxDC:'',
                crossSectionMinAC:'',
                crossSectionMaxAC:'',
                uInvRecommended:'',
                pInvRecommended:'',
                etaInvMax:'',
                etaInvEuropean:'',
                etaInvNominal:'',
                checked:false,
                isTriPhase:false,
                isUtilizingReacPower:false,

                uploadFile:'',
                pInvDCmax:'',
                uInvDcmax:'',
                uInvDcnom:'',
                uInvMPPmax:'',
                inputCount:1,
                uInvMPPminFrom:'',
                uInvMPPminTo:'',
                uInvPVmin:'',
                uInvPVmax:'',
                pInvACmax:'',
                pInvACnom:'',
                powerFactorMin:'',
                uInvACFrom:'',
                uInvACTo:'',
                gridFrequFrom:'',
                gridFrequTo:'',
                isStorage:"",
                recommend:"",
                modelSeries:"",
                iInvDCObject:[{
                    iInvDCMax:'',
                    iInvSCMax:'',
                    stringsParallelCount:'',
                    iInvPWMax:'',
                }]
            },
            imgBaseUrl:'',
            modelSeriesList:[]
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        this.tdictionaryGetDictionaryBatch()
    },
    methods: {
        returnProNumber(number,text){
            let lan = localStorage.getItem('lan')
            if(lan == 'zh_CN'){
                return number +' '+ text
            }else if(lan == 'en_US'){
                return text +' '+ number
            }
        },
        numberChange(){
            if(this.form.inputCount>50){
                this.form.inputCount = 50
            }else if(this.form.inputCount <1){
                this.form.inputCount = 1
            }
            let num = this.form.inputCount - this.form.iInvDCObject.length
            if(num > 0){
                for(let i = this.form.iInvDCObject.length;i<this.form.inputCount;i++){
                    let obj = {
                        iInvDCMax: '',
                        iInvSCMax: '',
                        stringsParallelCount: '',
                        iInvPWMax: ''
                    }
                    this.form.iInvDCObject.push(obj)
                }
            }else{
                this.form.iInvDCObject.splice(this.form.inputCount, Math.abs(num))
            }
        },
        async systemTinverterSave(){
            let obj = JSON.parse(JSON.stringify(this.form))
            obj.checked = obj.checked ? 1:0
            obj.isTriPhase = obj.isTriPhase ? 1:0
            obj.isUtilizingReacPower = obj.isUtilizingReacPower ? 1:0
            obj.iInvDCObject = JSON.stringify(obj.iInvDCObject)
            // obj = Object.assign({},obj,obj.iInvDCObject)
            obj.uploadFile = this.form.uploadFile
            obj.stringsNumber = this.form.inputCount
            let res = await this.API.systemTinverterSave(obj)
            if(res.code == 2){
                this.$message({
                    message: this.$t('system.error'),
                    type: 'warning'
                });
            }
            if(res.code == 1){
                this.$message({
                    message: this.$t('project.inv.repeat'),
                    type: 'warning'
                });
            }
            if(res.code == 0){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                await this.$router.push({path: '/adminInverter'});
            }
            
            
        },
        save(formName){
            this.$refs[formName].validate((valid) => {
              if (valid) {
                  this.systemTinverterSave()
              } else {
                console.log('error submit!!');
                return false;
              }
            });
        },
        returnImgUrl(){
            let that = this
            if(this.form.uploadFile){
                var reader=new FileReader();
                reader.readAsDataURL(this.form.uploadFile);
                reader.onload=function (e) {
                     that.imgBaseUrl=e.target.result;
                }
            }
        },
        async tdictionaryGetDictionaryBatch(){
            let res = await this.API.tdictionaryGetDictionaryBatch({
                dictCodes:'INVERTER_TYPES',
                lang:localStorage.getItem('lang')||'zh_CN'
            })
            this.modelSeriesList = res.INVERTER_TYPES
        },
    },
};
</script>

<style  lang="less" scoped>
    .save-main-box{
        display: flex;
        justify-content: space-evenly;
    }
</style>

